:root {
  --primary-color-verylight: #d1d7f6;
  --primary-color-light: #eff1fc;
  --primary-color: #152370;
  --primary-color-dark: #000a57;
  --primary-color-mild: #4253a9;
  --secondary-color-light: #c082ff;
  --secondary-color: #7335cb;
  --secondary-color-dark: #5a1cb2;
  --secondary-color-mild: #a668fe;
  --danger: #dc2626;
}
