@import url('variables.css');

.table-space {
  border-collapse: separate;
  border-spacing: 0 2em;
}

.ellipsis-multiline {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: #fff;
}
.red-border {
  border: 1px solid var(--danger);
}

.app-form label.required:after {
  content: ' *';
  color: var(--danger);
}
